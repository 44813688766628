import { defineStore } from 'pinia';
import axios from 'axios';
import * as Sentry from '@sentry/vue';
import { useDataForLongStore } from '~/stores/dataForLong';

export const useUserStore = defineStore(
  'user',
  () => {
    const { $api } = useNuxtApp();

    const userCookie = useCookie('user');
    const notieStore = useNotieStore();
    const dataForLongStore = useDataForLongStore();

    const user = ref({});
    const clientId = ref('');
    const brandId = ref('');
    const isInitialGetUserStatesLoading = ref(true);

    const getUserStates = async (extraData) => {
      const res = await $api.web(extraData).getUserStates();
      const data = res?.data.data;

      if (data) {
        if (import.meta.client) {
          dataForLongStore.payment_schedule = data.data.contract_payments;
          dataForLongStore.order_id = data.data.order_id;

          delete data.data.contract_payments;
          delete data.data.order_id;
          user.value = Object.assign(user.value, data.data);

          /**
           * TASK: FC-7605
           * Logs a warning message to Sentry if the initial loading of user states is in progress and the user's status code is 0.
           * Captures any exceptions that occur during this process.
           */
          try {
            if (isInitialGetUserStatesLoading.value && user.value.status_code === 0) {
              Sentry.captureMessage(`[warning] LK Status_code:0`, 'warning');
            }
          } catch (error) {
            Sentry.captureException(error);
          }
        } else {
          userCookie.user = Object.assign(userCookie.user, data.data);
        }
      }

      const statusCodeCookie = useCookie('status_code');
      if (statusCodeCookie.value === 5) {
        user.value.status_code = 5;
      }
    };

    const unsubscribe = async () => {
      const config = {
        method: 'get',
        url: ' https://api-arm.denginadom.ru/v1/serviceSite/Unsubscribe',
        params: {
          account_number: user.value.contract_number,
        },
        headers: {
          brand: 'prostoyvopros',
        },
      };
      await axios(config);
      notieStore.addNotification({
        type: 'success',
        message: 'Успешно',
      });

      user.value.recurrent_is_active = false;
    };

    const $reset = () => {
      user.value = {};
    };

    return {
      user,
      clientId,
      brandId,

      unsubscribe,
      isInitialGetUserStatesLoading,
      getUserStates,
      $reset,
    };
  },
  {
    persist: true,
  },
);
