import { defineStore } from 'pinia';

export const useAppStore = defineStore(
  'app',
  () => {
    const userStore = useUserStore();

    const modals = reactive({
      backCall: false,
      backCallFilesScenario: false,
      loanRecentlyCreated: false,
      payment: false,
      paymentIssue: false,
      correctionSuccess: false,
    });

    const fail1c = ref(false);
    const verificationCode = ref('');
    const qsToken = ref('');
    const menuMobileOpen = ref(false);
    const isGetProductChoiceInfoLoading = ref(false);
    const signingDocsFormationProgress = ref(1);
    const averageMonthlyPaymentProgress = ref(1);

    const setInitialValues = () => {
      if (
        Object.keys(userStore?.user).length &&
        ![13, 16, 17].includes(userStore?.user?.status_code)
      ) {
        signingDocsFormationProgress.value = 1;
      }

      if (Object.keys(userStore?.user).length && ![15, 18].includes(userStore?.user?.status_code)) {
        averageMonthlyPaymentProgress.value = 1;
      }
    };

    const setMenuMobileOpen = (value) => {
      menuMobileOpen.value = value;
    };

    const $reset = () => {
      fail1c.value = false;
      verificationCode.value = '';
      qsToken.value = '';
      menuMobileOpen.value = false;
      isGetProductChoiceInfoLoading.value = false;
    };

    return {
      modals,
      fail1c,
      verificationCode,
      qsToken,
      signingDocsFormationProgress,
      averageMonthlyPaymentProgress,
      menuMobileOpen,
      setMenuMobileOpen,
      setInitialValues,
      isGetProductChoiceInfoLoading,
      $reset,
    };
  },
  {
    persist: {
      paths: ['qsToken', 'signingDocsFormationProgress', 'averageMonthlyPaymentProgress'],
    },
  },
);
