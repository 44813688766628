import Instances from './instances';
import web from './web';
import geo from './geo';
import disk from './disk';
import chat from './chat';
import diskChat from './diskChat';
import { defineNuxtPlugin, useRuntimeConfig } from '#imports';

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  return {
    provide: {
      api: {
        web: () => web(config, Instances.web),
        geo: () => geo(config, Instances.geo),
        disk: (tag) => disk(config, Instances.disk, tag),
        chat: () => chat(config, Instances.chat),
        diskChat: () => diskChat(config, Instances.diskChat),
        payment: () => payment(config, Instances.payment),
      },
    },
  };
});
