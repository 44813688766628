import { io } from 'socket.io-client';
import { useChatStore } from '~/stores/chat';

export default defineNuxtPlugin(() => {
  const route = useRoute();
  const config = useRuntimeConfig();
  const chatStore = useChatStore();

  const sockets = {};

  const defaultSocketHandler = (socket) => {
    socket.on('connect', () => {
      // eslint-disable-next-line
      console.log({ message: '[socket.io] connected' });
      chatStore.connectToRoom();
    });

    socket.on('disconnect', () => {
      // eslint-disable-next-line
      console.log({ message: '[socket.io] disconnected' });
    });

    socket.on('message.client:typing', () => {
      chatStore.setOperatorTyping();
    });

    socket.on('message.client:read', () => {
      chatStore.readMyMessages();
    });

    socket.on('client.appeal:opened', (res) => {
      chatStore.appealId = res.appeal_id;
    });

    socket.on('client.appeal:closed', (res) => {
      chatStore.appealId = res.appeal_id;
    });

    socket.on('message.client:send', (res) => {
      if (route.name === 'chat') {
        res.unread = false;
      }

      chatStore.messages.push(res);
      chatStore.setOperatorTyping(false);
    });
  };

  const connect = (url, label, settings = null) => {
    return new Promise((resolve, reject) => {
      if (!sockets[label]) {
        try {
          sockets[label] = io(url, settings);
          resolve(sockets[label]);
        } catch (e) {
          reject(e);
        }
      } else {
        resolve(sockets[label]);
      }
    });
  };

  const clear = () => {
    Object.keys(sockets).forEach((label) => {
      try {
        sockets[label].disconnect();
        delete sockets[label];
      } catch (err) {
        throw new Error(err);
      }
    });
  };

  const useConnection = async ({
    url = `${config.public.wsChatServerUrl}`,
    label = 'main',
    settings = {
      query: { source: 'lk', token: chatStore.wssToken },
    },
    handler = defaultSocketHandler,
  } = {}) => {
    try {
      if (!url) return;

      const socket = await connect(url, label, settings);
      if (handler) handler(socket);

      return socket;
    } catch (err) {
      throw new Error(err);
    }
  };

  return {
    provide: {
      ws: {
        sockets,
        connect,
        clear,
        useConnection,
      },
    },
  };
});
